
<template>
  <div class="origin fll--viewport">
    <form @submit.stop.prevent="handleSubmit" class="pos--center-center flx">
      <input placeholder="Please re-enter email" type="email" v-model="email">
      
      <button type="submit">Sign in</button>
    </form>
  </div>
</template>


<script>
import { onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';


export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const email = ref('')


    const signIn = async () => {
      const {success} = await store.dispatch('auth/confirm', {href: window.location.href, email: email.value});
      
      if (success) {
        router.push({
          name: 'Home'
        })
      }
    }

    onMounted(async () => {
      try {
        const {success} = await store.dispatch('auth/check');
        if (success) {
          router.push({
            name: 'Home'
          });
        } else {
          signIn()
        }
      } catch (err) {
        console.error(err)
      }
    });

    return {
      signIn,
      handleSubmit: () => {
        signIn();
        email.value = '';
      } ,
      email
    }
  }
}
</script>